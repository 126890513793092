import http from './httpService'
import {getPagination} from "../utils/Utils";

export const API_ENDPOINTS = {

    AUTH_LOGIN : 'auth/login',
    AUTH_ME : 'auth/me',
    AUTH_CHANGE_PASS : 'auth/password/change',

    CATEGORIES : 'categories',
    MASTERS : 'masters',
    REGIONS: 'regions',
    SERVICES : 'services',
    COMPANIES: 'carservices',
    COMPANIES_COUNT: 'carservices/count',
    USERS: 'users',
    USERS_COUNT: 'users/count',
    ACTIVE_USERS_COUNT: 'users/active/count',
    BLOCKED_USERS_COUNT: 'users/blocked/count',
    SOS: 'sos',
    AUTO_PART: 'autopart',
    REVIEW: 'review',
    ADS: 'ads',
    ADS_DELETE: 'ads/delete',
    SOS_DELETE: 'sos/delete',
    AUTO_PART_DELETE: 'autopart/delete',

    SEND: 'send',

    ADD: 'create',
    UPDATE: 'update',
    DELETE: 'delete',
    DELETE_MASTER: 'master/delete',
    UPDATE_MASTER: 'master/update',
    ADD_REGION: 'region/create',
    ADD_CATEGORY: 'category/create',
    UPDATE_CATEGORY: 'category/update',
    ADD_MASTER: 'master/create',
    ADD_SERVICE: 'service/create',
    UPDATE_SERVICE: 'service/update',

    UPLOAD_IMAGE: 'upload/image',

}

export const authAPI = {
    me: (params) => http.get(API_ENDPOINTS.AUTH_ME, params),
    login: (params) => http.post(API_ENDPOINTS.AUTH_LOGIN, params),
    logout: (params) => http.delete(API_ENDPOINTS.AUTH_LOGIN, params),
    changePassword: (params) => http.post(API_ENDPOINTS.AUTH_CHANGE_PASS, params)
}


export const API = {
    categories: (params) => http.get(API_ENDPOINTS.CATEGORIES, params),
    masters: (pagination, cats) => http.get(API_ENDPOINTS.MASTERS + getPagination(pagination) + `&cats=${cats}`),
    regions: (params) => http.get(API_ENDPOINTS.REGIONS, params),
    services: (params) => http.get(API_ENDPOINTS.SERVICES, params),
    companies: (pagination, cats) => http.get(API_ENDPOINTS.COMPANIES + getPagination(pagination) + `&cats=${cats}`),
    companiesCount: (params) => http.get(API_ENDPOINTS.COMPANIES_COUNT, params),
    users: (params) => http.get(API_ENDPOINTS.USERS, params),
    usersCount: (params) => http.get(API_ENDPOINTS.USERS_COUNT, params),
    activeUsersCount: (params) => http.get(API_ENDPOINTS.ACTIVE_USERS_COUNT, params),
    blockedUsersCount: (params) => http.get(API_ENDPOINTS.BLOCKED_USERS_COUNT, params),
    sos: (params) => http.get(API_ENDPOINTS.SOS, params),
    sosDelete: (params) => http.post(API_ENDPOINTS.SOS_DELETE, params),
    autoPart: (params) => http.get(API_ENDPOINTS.AUTO_PART, params),
    autoPartDelete: (params) => http.post(API_ENDPOINTS.AUTO_PART_DELETE, params),
    review: (params) => http.get(API_ENDPOINTS.REVIEW, params),
    ads: (params) => http.get(API_ENDPOINTS.ADS, params),
    adsDelete: (params) => http.post(API_ENDPOINTS.ADS_DELETE, params),

    send: (params) => http.post(API_ENDPOINTS.SEND, params),

    add: (params) => http.post(API_ENDPOINTS.ADD, params),
    addMaster: (params) => http.post(API_ENDPOINTS.ADD_MASTER, params),
    deleteMaster: (params) => http.post(API_ENDPOINTS.DELETE_MASTER, params),
    update: (params) => http.post(API_ENDPOINTS.UPDATE, params),
    delete: (params) => http.post(API_ENDPOINTS.DELETE, params),
    addCategory: (params) => http.post(API_ENDPOINTS.ADD_CATEGORY, params),
    addRegion: (params) => http.post(API_ENDPOINTS.ADD_REGION, params),
    updateCategory: (params) => http.post(API_ENDPOINTS.UPDATE_CATEGORY, params),
    addService: (params) => http.post(API_ENDPOINTS.ADD_SERVICE, params),
    updateService: (params) => http.post(API_ENDPOINTS.UPDATE_SERVICE, params),
    updateMaster: (params) => http.post(API_ENDPOINTS.UPDATE_MASTER, params),

    uploadImage: (params) => http.post(API_ENDPOINTS.UPLOAD_IMAGE, params),
}




