import axios from 'axios';
import { getUserData } from '../utils/Utils';

axios.interceptors.request.use(function(config) {
	config.baseURL = process.env.REACT_APP_API_URL;

	config.headers.common['Access-Control-Allow-Origin'] = '*'
	config.headers.common['Authorization'] = `Bearer ${getUserData()?.token}`
	config.headers.common['Accept-Language'] = 'ru'
	config.timeout = 60000
	return config;
});

axios.interceptors.response.use(
	response =>
		new Promise((resolve, reject) => {
			resolve(response);
		}),
	error => {
		//console.log('error.response.data', error.response);
		if (!error.response) {
			return new Promise((resolve, reject) => {
				reject(error);
			});
		}
		if (error.response.status === 403 || error.response.status === 401) {
			localStorage.removeItem('userData');
			window.location = '/login';
		} else if (error.response.status === 428) {
			window.location = '/changepassword';
		} else {
			return new Promise((resolve, reject) => {
				reject(error);
			});
		}
	}
);

const httpService = {
	get: axios.get,
	post: axios.post,
	put: axios.put,
	delete: axios.delete,
	patch: axios.patch
}

export default httpService;
