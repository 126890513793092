import { lazy } from "react";

const PagesRoutes = [
  // PAGES,
  {
    path: "/login",
    component: lazy(() => import("../../view/pages/authentication-modern/login")),
    layout: "FullLayout",
  },
  // {
  //   path: "/dashboard",
  //   component: lazy(() => import("../../view/main/dashboard/analytics")),
  //   layout: "VerticalLayout",
  // },
  {
    path: "/users",
    component: lazy(() => import("../../view/pages/user/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/sos",
    component: lazy(() => import("../../view/pages/sos/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/autoparts",
    component: lazy(() => import("../../view/pages/autoparts/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/ads",
    component: lazy(() => import("../../view/pages/ads/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/review",
    component: lazy(() => import("../../view/pages/review/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/autoservice",
    component: lazy(() => import("../../view/pages/autoservice/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/category",
    component: lazy(() => import("../../view/pages/category")),
    layout: "VerticalLayout",
  },
  {
    path: "/masters",
    component: lazy(() => import("../../view/pages/masters/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/regions",
    component: lazy(() => import("../../view/pages/regions")),
    layout: "VerticalLayout",
  },
  {
    path: "/service",
    component: lazy(() => import("../../view/pages/service")),
    layout: "VerticalLayout",
  },
  {
    path: "/error-404",
    component: lazy(() => import("../../view/pages/errors/404")),
    layout: "FullLayout",
  },
  {
    path: "/profile",
    component: lazy(() => import("../../view/pages/profile")),
    layout: "VerticalLayout",
  },
  {
    path: "/profile/password-change",
    component: lazy(() => import("../../view/pages/profile")),
    layout: "VerticalLayout",
  },
];

export default PagesRoutes;