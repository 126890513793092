import {Notepad, Setting2, Car, UserSquare, WifiSquare, SmartCar, VolumeLow1, SmsStar} from 'iconsax-react';

import IntlMessages from "../../layout/components/lang/IntlMessages";
import {Activity} from "react-iconly";
import {MdSos} from "react-icons/md";

const pages = [
    // {
    //     header: <IntlMessages id="sidebar-pages" />,
    // },
    {
        id: "dashboard",
        title: 'Дашбоард',
        icon: <Activity size={18} /> ,
        navLink: "/",
    },
    {
        id: "users",
        title: 'Пользователи',
        icon: <UserSquare size={18} />,
        navLink: "/users",
    },
    {
        id: "autoservice",
        title: 'Автосервисы',
        icon: <Car size={18} />,
        navLink: "/autoservice",
    },
    {
        id: "category",
        title: 'Категории',
        icon: <Notepad size={18} />,
        navLink: "/category",
    },
    {
        id: "masters",
        title: 'Мастера',
        icon: <Car size={18} />,
        navLink: "/masters",
    },
    {
        id: "regions",
        title: 'Регионы',
        icon: <Notepad size={18} />,
        navLink: "/regions",
    },
    {
        id: "services",
        title: 'Услуги',
        icon: <Setting2 size={18} />,
        navLink: "/service",
    },
    {
        id: "sos",
        title: 'SOS',
        icon: <WifiSquare size={18} />,
        navLink: "/sos",
    },
    {
        id: "autoparts",
        title: 'Автозапчасти',
        icon: <SmartCar size={18} />,
        navLink: "/autoparts",
    },
    {
        id: "ads",
        title: 'Реклама',
        icon: <VolumeLow1 size={18} />,
        navLink: "/ads",
    },
    {
        id: "review",
        title: 'Отзывы',
        icon: <SmsStar size={18} />,
        navLink: "/review",
    },
    // {
    //     id: "errors",
    //     title: <IntlMessages id="sidebar-pages-error" />,
    //     icon: <Award size={18} />,
    //     children: [
    //         {
    //             id: "error-404",
    //             title: "404",
    //             navLink: "/pages/error-404",
    //         },
    //     ],
    // },
];

export default pages