import {Award, Car, Notepad, Setting2} from 'iconsax-react';

import IntlMessages from "../../layout/components/lang/IntlMessages";
import {Activity} from "react-iconly";

const pages = [
    {
        id: "dashboard",
        title: 'Дашбоард',
        icon: <Activity size={18} /> ,
        navLink: "/",
    },
    {
        id: "autoservice",
        title: 'Автосервисы',
        icon: <Car size={18} />,
        navLink: "/autoservice",
    },
    {
        id: "category",
        title: 'Категории',
        icon: <Notepad size={18} />,
        navLink: "/category",
    },
    {
        id: "services",
        title: 'Услуги',
        icon: <Setting2 size={18} />,
        navLink: "/service",
    },
];

export default pages