//import { DefaultRoute } from '../router/routes'
import moment from 'moment';


export const mrzToArray = (value)  =>{
	if(!value) {
		return ''
	}
	const mrz = []
	if(value.length === 88) {
		mrz[0] = value.slice(0, 44)
		mrz[1] = value.slice(44)
	} else {
		mrz[0] = value.slice(0, 30)
		mrz[1] = value.slice(30, 60)
		mrz[2] = value.slice(60)
	}
	return mrz
}



export function addSpaceToString(inputString) {
	// Using a regular expression to insert a space between letters and numbers
	const result = inputString.replace(/([A-Za-z])(\d)/g, '$1 $2');
	return result;
}


// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = num =>
	num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '');

// ** Checks if the passed date is today
const isToday = date => {
	const today = new Date();
	return (
		/* eslint-disable operator-linebreak */
		date.getDate() === today.getDate() &&
		date.getMonth() === today.getMonth() &&
		date.getFullYear() === today.getFullYear()
		/* eslint-enable */
	);
};

export const isStatus = status => {
	return status <= 300;
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
	value,
	formatting = { month: 'short', day: 'numeric', year: 'numeric' }
) => {
	if (!value) return value;
	return new Intl.DateTimeFormat('ru-RU', formatting).format(new Date(value));
};

export const formatDateMoment = value => {
	return moment(value).format('YYYY-MM-DD');
};

export const checkDate = (start, end) => {
	if (start === end) {
		return ""
	} else {
		return moment(start).format('YYYY-MM-DD')
	}
}

export const formatDt = value => {
	return moment(value).format('DD-MM-YYYY');
};


export const formatTm = value => {
	return moment(value).format('hh:mm');
};
export const formatCurrency = number => {
	let value = number;
	return value?.toLocaleString('ru-RU', {
		style: 'currency',
		currency: 'UZS',
		minimumFractionDigits: 0
	});
};

export const priceFormat = (price, lang) => {
	return price
		.toLocaleString('ru-RU', {
			style: 'currency',
			currency: 'UZS',
			currencyDisplay: 'code',
			maximumFractionDigits: 0
		})
		.replace('UZS', lang);
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
	const date = new Date(value);
	let formatting = { month: 'short', day: 'numeric' };

	if (toTimeForCurrentDay && isToday(date)) {
		formatting = { hour: 'numeric', minute: 'numeric' };
	}

	return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData');
export const getUserData = () => JSON.parse(localStorage.getItem('userData'));
export const getToken = () => JSON.parse(localStorage.getItem('token'));

export function trimSpaces(str) {
	return str.replace(/^\s+|\s+$/g, '');
}

export const getPagination = (pagination) => {
	return `?perPage=${pagination?.limit}&page=${pagination?.page}`
}

export const getName = (names, lang) => {
	return names?.length > 0 ? names.find(name => name.lang === lang).value : ''
}

export const isAuthenticated = () => {
	const token = getUserData('userData')?.token;
	return !!token;
}


/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
// export const getHomeRouteForLoggedInUser = userRole => {
//   if (userRole === 'admin') return DefaultRoute
//   if (userRole === 'user') return DefaultRoute
//   if (userRole === 'client') return '/access-control'
//   return '/login'
// }

export const getFullName = (firstName, lastName) => {
	return `${lastName} ${firstName }`
}

export const formatPhoneNumber = (str) => {
	let cleaned = ('' + str).replace(/\D/g, '');

	let match = cleaned.match(/^(998|)?(\d{2})(\d{3})(\d{2})(\d{2})$/);

	if (match) {
		return ['(', match[2], ') ', match[3], '-', match[4],'-', match[5]].join('')
	}

	return null;
}

export const errorHandler = error => {
	const { request, response } = error;

	if (response) {
		const errMsg = response?.data?.error?.errMsg;
		const { message } = response.data;
		const status = response.status;
		return {
			message: message || errMsg,
			status
		};
	} else if (request) {
		//request sent but no response received
		return {
			message: 'server time out',
			status: 503
		};
	} else {
		// Something happened in setting up the request that triggered an Error
		return {
			message: 'opps! something went wrong while setting up request'
		};
	}
};

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
	...theme,
	colors: {
		...theme.colors,
		primary25: '#7367f01a', // for option hover bg-color
		primary: '#7367f0', // for selected option bg-color
		neutral10: '#7367f0', // for tags bg-color
		neutral20: '#ededed', // for input border-color
		neutral30: '#ededed' // for input hover border-color
	}
});
