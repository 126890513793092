import antdFR from "antd/lib/locale-provider/fr_FR";
import saMessages from "../locales/fr_FR.json";

const frLang = {
    messages: {
        ...saMessages
    },
    antd: antdFR,
    locale: 'fr-FR',
};

export default frLang;